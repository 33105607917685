<template>
  <div>
    <v-flex text-right class="my-2">
      <v-btn
        color="red"
        @click="imprimirReporte()"
        class="mx-1"
        :loading="loading"
        :disabled="loading"
        :dark="!loading"
        small
      >
        <v-icon>mdi-file-pdf-box</v-icon> Imprimir
      </v-btn>
    </v-flex>
    <v-data-table
      dense
      :headers="caberas"
      :items="reportsRangeDays"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.name }}</td>
          <td class="center">{{ row.item._MAS_DE_30_DIAS }}</td>
          <td class="center">{{ row.item._16_30_DIAS }}</td>
          <td class="center">{{ row.item._11_15_DIAS }}</td>
          <td class="center">{{ row.item._6_10_DIAS }}</td>
          <td class="center">{{ row.item._3_5_DIAS }}</td>
          <td class="center">{{ row.item._0_2_DIAS }}</td>
          <td class="center">{{ row.item.TOTAL }}</td>
        </tr>
      </template>
    </v-data-table>
    <!-- CARGANDO DATOS -->
    <!-- <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card color="primary" dark>
          <v-card-text>
            Cargando....
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog> -->
    <!-- CARGANDO DATOS -->
  </div>
</template>

<script>
import funcion from "@/mixins/funciones";
import axios from "axios";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  mixins: [funcion],
  data() {
    return {
      loading: false,
      caberas: [
        { value: "status", text: "STATUS COTIZACIÓN", align: "left" },
        { value: "treinta", text: "> A 30 DIAS", align: "center" },
        { value: "dieciseis", text: "16 - 30 DIAS", align: "center" },
        { value: "once", text: "11 - 15 DIAS", align: "center" },
        { value: "seis", text: "6 - 10 DIAS", align: "center" },
        { value: "tres", text: "3 - 5 DIAS", align: "center" },
        { value: "cero", text: "0 - 2 DIAS", align: "center" },
        { value: "total", text: "TOTAL", align: "center" },
      ],
      datos: [],
    };
  },
  mounted() {
    this.getReportsRangeDays();
  },
  computed: {
    ...mapState(["dialog", "reportsRangeDays"]),
  },
  methods: {
    ...mapMutations(["opcioneMenuLista", "setPrecotizarFlag"]),
    ...mapActions(["getReportsRangeDays"]),

    async imprimirReporte() {
      this.loading = true;
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "aplication/json",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers":
          "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
        responseType: "arraybuffer",
      };
      let url =
        process.env.VUE_APP_URL_REPORT+"listado_cotizacion_rango_dias_export";
      await axios.post(url, null, headers).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let name = this.uuidv4();
        link.setAttribute("download", name + ".pdf");
        document.body.appendChild(link);
        link.click();
        this.loading = false;
      });
    },
  },
  beforeMount() {},
};
</script>

<style scope>
.v-data-table__empty-wrapper {
  min-height: 500px;
  box-sizing: border-box;
  padding: auto;
}

* {
  box-sizing: border-box;

  /* font-size: .8rem !important ; */
  /* padding: 0 !important; */
  /* margin: 0 !important; */
}
.center {
  align-content: center;
  text-align: center;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
